<template>
  <div>

    <base-header class="pb-2 pt-5 pt-md-8">
      <b-row>
        <b-col xl="3" md="6">
          <stats-card title="Cartes"
                      type="gradient-blue"
                      :sub-title="countMaps"
                      icon="ni ni-map-big"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ countMapsInStock }}</span>
              <span class="text-nowrap">en stock</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Parcours"
                      type="gradient-green"
                      :sub-title="countTracks"
                      icon="ni ni-square-pin"
                      class="mb-4">

            <template slot="footer">
              <!--<span class="text-success mr-2">12.18%</span>
              <span class="text-nowrap">parcouru</span>-->
              <br>
            </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>

    <base-header class="pb-2 pt-2 pt-md-4">
      <b-row>
        <b-col xl="3" md="6" v-for="activityStatistics in activitiesStatistics" :key="activityStatistics.id">

          <stats-card :title="activityStatistics.fullName"
                      type="gradient-green"

                      icon="ni ni-square-pin"
                      class="mb-4">
            <!-- :sub-title="countTracks" -->

            <template slot="icon">
              <div class="icon icon-shape text-white rounded-circle shadow">
                <span v-html="activityStatistics.icon"></span>
              </div>
            </template>

            <template slot="footer">
              <span class="text-success mr-2">{{ activityStatistics.tracksCount }}</span>
              <span class="text-nowrap">parcours</span>
            </template>

          </stats-card>

        </b-col>
      </b-row>
    </base-header>

  </div>
</template>
<script>

  import StatsCard from '@/components/Cards/StatsCard';

  import {mapGetters, mapActions} from 'vuex';
  import AuthService from "../services/AuthService";

  export default {
    components: {
      StatsCard,
    },
    computed: {
      ...mapGetters({
        mapsInStock: 'maps/mapsInStock',
        maps: 'maps/maps',
        tracks: 'tracks/tracks',
        activities: 'activities/activities',
        activitiesStatistics: 'activities/activitiesStatistics',
      }),
      countMaps () {
        return this.maps.length+"";
      },
      countMapsInStock () {
        return this.mapsInStock.length+"";
      },
      countTracks () {
        return this.tracks.length+"";
      },
    },
    methods: {
      ...mapActions({
        loadMaps: 'maps/loadMaps',
        loadTracks: 'tracks/loadTracks',
        loadActivities: 'activities/loadActivities',
        loadActivitiesStatistics: 'activities/loadActivitiesStatistics'
      }),
    },
    mounted() {
      this.loadMaps()
      this.loadTracks()
      this.loadActivitiesStatistics()
    }
  };
</script>
<style>
.el-table .cell{
  padding-left: 0px;
  padding-right: 0px;
}
</style>
